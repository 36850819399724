import {
  ISquare,
  GameState,
  Position,
  IPiece,
  PieceType,
  Mode,
  State,
} from "../types";
import { Square } from "../Square";
import { Piece } from "../Piece";

export class GameStateManager {
  private gameState: GameState;

  constructor() {
    // set initial state
    this.gameState = {
      mode: Mode.Dev,
      state: State.PreGame,
      board: this.initializeBoard(),
      isLightTurn: true,
    };
  }

  initializeBoard(): ISquare[][] {
    const rows = 8;
    const columns = 5;
    const board: ISquare[][] = [];

    for (let row = 0; row < rows; row++) {
      const reversedRow = rows - 1 - row;
      board[reversedRow] = new Array(columns)
        .fill(null)
        .map(() => this.getEmptySquare());
    }

    return board;
  }

  getBoardState(): ISquare[][] {
    return this.gameState.board;
  }

  getGameState(): GameState {
    return this.gameState;
  }

  setGameState(gameState: GameState): void {
    // this seems dangerous
    this.gameState = gameState;
  }

  setGameStateState(state: State): void {
    this.gameState.state = state;
  }

  setMode(mode: Mode) {
    this.gameState.mode = mode;
  }

  nextGameState() {
    switch (this.gameState.state) {
      case State.PreGame:
        this.gameState.state = State.Playing;
        break;
      case State.Playing:
        this.gameState.state = State.PostGame;
        break;
      default:
        break;
    }
  }

  setLightPlayer(playerId: string) {
    this.gameState.lightPlayer = playerId;
  }

  setDarkPlayer(playerId: string) {
    this.gameState.darkPlayer = playerId;
  }

  changeTurns() {
    this.gameState.isLightTurn = !this.gameState.isLightTurn;
  }

  getPiecesAt(position: Position): IPiece[] {
    return this.gameState.board[position.row][position.col].Pieces;
  }

  getEmptySquare(): ISquare {
    return new Square([]);
  }

  emptyBoard(): void {
    this.gameState.board.forEach((row) => {
      row.forEach((square) => {
        square.Pieces = [];
      });
    });
  }

  placePiece(piece: IPiece, position: Position): void {
    this.gameState.board[position.row][position.col].Pieces.push(piece);
  }

  removePiece(position: Position, piece: IPiece): void {
    const square = this.getSquare(position);
    square.Pieces = square.Pieces.filter((x) => x !== piece);
  }

  addPiece(position: Position, piece: IPiece): void {
    const square = this.getSquare(position);
    square.Pieces.push(piece);
  }

  getSquare(position: Position): ISquare {
    const { row, col } = position;
    if (
      row < 0 ||
      row >= this.gameState.board.length ||
      col < 0 ||
      col >= this.gameState.board[0].length
    ) {
      throw new Error("Position is out of bounds");
    }
    return this.gameState.board[row][col];
  }

  findPiecePosition(piece: IPiece): Position | null {
    for (let row = 0; row < this.gameState.board.length; row++) {
      for (let col = 0; col < this.gameState.board[row].length; col++) {
        const square = this.gameState.board[row][col];
        if (square.Pieces.includes(piece)) {
          return { row, col };
        }
      }
    }
    return null; // Piece not found on the board
  }

  setStartingPiece(
    pieceType: PieceType,
    isLight: boolean,
    col: 0 | 1 | 2 | 3 | 4
  ): void {
    const row = isLight ? 0 : 7;
    const square = this.getSquare({ row, col });
    square.Pieces[0] = new Piece(pieceType, isLight);
  }
}
