import globalStyles from "./App.module.css";
import BoardContainer from "./components/BoardContainer";
import { GameContextProvider } from "./components/GameContext";

function App() {
  return (
    <div className={globalStyles.app}>
      <GameContextProvider>
        <BoardContainer />
      </GameContextProvider>
    </div>
  );
}

export default App;
