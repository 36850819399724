import { ZodiacEngine } from "../services/zodiacEngine/zodiacEngine";

// this class could potentially be moved into the base engine
class ZodiacEngineService {
  static instance: ZodiacEngineService;
  zodiacEngine!: ZodiacEngine;

  constructor() {
    if (ZodiacEngineService.instance) {
      return ZodiacEngineService.instance;
    }
    this.zodiacEngine = new ZodiacEngine();
    ZodiacEngineService.instance = this;
  }
}

const engineService = new ZodiacEngineService();
export default engineService;
