import {
  Position,
  IPiece,
  MoveType,
  IMove,
  SideEffect,
  Effect,
  PieceType,
} from "../types";
import { GameStateManager } from "./GameStateManager";
import { animalPieceTypes } from "../const";

export class MovementManager {
  private readonly gameStateManager: GameStateManager;

  constructor(gameStateManager: GameStateManager) {
    this.gameStateManager = gameStateManager;
  }

  makeMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece | null,
    moveType: MoveType,
    sideEffects: SideEffect[]
  ): boolean {
    let validMoves = piece.getValidMovesForPiece(
      this.gameStateManager.getBoardState(),
      fromPosition
    );

    if (
      validMoves.some(
        (x) =>
          x.position.col === toPosition.col && x.position.row === toPosition.row
      )
    ) {
      piece.hasMoved = true;

      switch (moveType) {
        case MoveType.regular:
          this.regularMove(fromPosition, toPosition, piece);
          break;
        case MoveType.traverse:
          this.traverseMove(fromPosition, toPosition, piece);
          break;
        case MoveType.faint:
          this.faintMove(fromPosition, toPosition, piece);
          break;
        case MoveType.capture:
          this.captureMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        case MoveType.house:
          this.houseMove(fromPosition, toPosition, piece, toPiece!);
          break;
        case MoveType.trample:
          this.trampleMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        case MoveType.infect:
          this.infectMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        case MoveType.simpleentangle:
          this.simpleEntangleMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        case MoveType.moveentangle:
          this.moveEntangleMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        case MoveType.splitentangle:
          this.splitEntangleMove(
            fromPosition,
            toPosition,
            piece,
            toPiece!,
            sideEffects
          );
          break;
        default:
          throw new Error(`Unhandled move type: ${moveType}`);
      }

      if (piece.isInfected) {
        this.moveVirusWithInfectedPiece(piece, fromPosition);
      }

      this.handleSideEffects(
        fromPosition,
        toPosition,
        piece,
        toPiece,
        moveType,
        sideEffects
      );

      // You might need to send updates or notify listeners here if necessary

      return true;
    } else {
      return false;
    }
  }

  handleSideEffects(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece | null,
    moveType: MoveType,
    sideEffects: SideEffect[]
  ): void {
    sideEffects.forEach((sideEffect) => {
      if (sideEffect.effect === Effect.capture) {
        sideEffect.pieces.forEach((capturedPiece) => {
          // Remove the captured piece from the board
          this.gameStateManager.removePiece(sideEffect.position, capturedPiece);

          // Apply poison logic
          if (
            capturedPiece.type === PieceType.Frog &&
            animalPieceTypes.includes(piece.type)
          ) {
            piece.isPoisoned = true;
          }

          // Apply zombify logic
          if (
            capturedPiece.type === PieceType.Fungi &&
            animalPieceTypes.includes(piece.type)
          ) {
            piece.isZombified = true;
          }
        });
      }

      // Meadow logic
      if (sideEffect.effect === Effect.meadow) {
        sideEffect.pieces.forEach((adjacentPiece) => {
          if (
            adjacentPiece.type === PieceType.Tree &&
            adjacentPiece.isLight !== piece.isLight
          ) {
            adjacentPiece.isMeadowed = true;
          }
        });
      }

      // House move logic
      if (sideEffect.effect === Effect.housemove) {
        sideEffect.pieces.forEach((sidePiece) => {
          if (sidePiece.type !== PieceType.Tree) {
            this.regularMove(fromPosition, sideEffect.position, sidePiece);
          }
        });
      }
    });
  }

  private moveVirusWithInfectedPiece(
    piece: IPiece,
    fromPosition: Position
  ): void {
    // Find the piece's current position after the move
    const currentPosition = this.gameStateManager.findPiecePosition(piece);

    if (!currentPosition) {
      console.error("Piece not found on the board after move.");
      return;
    }

    // Move the virus from fromPosition to currentPosition
    const fromSquare = this.gameStateManager.getSquare(fromPosition);
    const toSquare = this.gameStateManager.getSquare(currentPosition);

    // Find the virus piece on the fromSquare
    const virusPieceIndex = fromSquare.Pieces.findIndex(
      (p) => p.type === PieceType.Virus
    );

    if (virusPieceIndex !== -1) {
      const virusPiece = fromSquare.Pieces[virusPieceIndex];
      // Remove virus piece from fromSquare
      fromSquare.Pieces.splice(virusPieceIndex, 1);
      // Add virus piece to toSquare
      toSquare.Pieces.push(virusPiece);
    } else {
      console.error(
        "Virus piece not found on the infected piece's original square."
      );
    }
  }

  private faintMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece
  ): void {
    piece.isFainted = true;
  }

  private traverseMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece
  ): void {
    // Remove the piece from the fromPosition
    this.gameStateManager.removePiece(fromPosition, piece);
    // Add the piece to the toPosition
    this.gameStateManager.addPiece(toPosition, piece);
  }

  private infectMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece,
    sideEffects: SideEffect[]
  ): void {
    this.regularMove(fromPosition, toPosition, piece);
    toPiece.isInfected = true;

    const fromSquare = this.gameStateManager.getSquare(fromPosition);
    const piecesToRemove: IPiece[] = [];

    if (fromSquare.Pieces.length === 1) {
      fromSquare.Pieces.forEach((squarePiece) => {
        if (animalPieceTypes.includes(squarePiece.type)) {
          piecesToRemove.push(squarePiece);
        }
      });
    }

    if (piecesToRemove.length === 1) {
      this.gameStateManager.removePiece(fromPosition, piecesToRemove[0]);
    } else {
      console.log("The virus tried to capture 2 animals?");
    }
  }

  private simpleEntangleMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece,
    sideEffects: SideEffect[]
  ): void {
    // Apply entangle logic
    if (
      piece.type === PieceType.Octopus &&
      toPiece.isLight !== piece.isLight &&
      animalPieceTypes.includes(toPiece.type)
    ) {
      toPiece.isEntangled = true;
    }
  }

  private moveEntangleMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece,
    sideEffects: SideEffect[]
  ): void {
    // Apply entangle logic
    toPiece.isEntangled = true;
    this.regularMove(fromPosition, sideEffects[0].position, piece);
  }

  private splitEntangleMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece,
    sideEffects: SideEffect[]
  ): void {
    // Apply entangle logic
    toPiece.isEntangled = true;
    this.regularMove(fromPosition, sideEffects[0].position, piece);
  }

  regularMove(fromPosition: Position, toPosition: Position, piece: IPiece) {
    // Remove the piece from the fromPosition
    this.gameStateManager.removePiece(fromPosition, piece);
    // Add the piece to the toPosition
    this.gameStateManager.addPiece(toPosition, piece);
  }

  private laneMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece
  ): void {
    // Swap positions of the two pieces
    this.regularMove(fromPosition, toPosition, piece);
    this.regularMove(toPosition, fromPosition, toPiece);
  }

  private trampleMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece | null,
    sideEffects: SideEffect[]
  ): void {
    const rowDiff = toPosition.row - fromPosition.row;
    const colDiff = toPosition.col - fromPosition.col;
    const rowStep = rowDiff === 0 ? 0 : rowDiff > 0 ? 1 : -1;
    const colStep = colDiff === 0 ? 0 : colDiff > 0 ? 1 : -1;

    let currentRow = fromPosition.row + rowStep;
    let currentCol = fromPosition.col + colStep;

    // Traverse the path to the destination
    while (currentRow !== toPosition.row || currentCol !== toPosition.col) {
      const currentPosition = { row: currentRow, col: currentCol };
      const square = this.gameStateManager.getSquare(currentPosition);
      const piecesAtPosition = square.Pieces.slice(); // Copy to avoid mutation issues

      if (piecesAtPosition.length > 0) {
        // Collect capture side effects
        sideEffects.push({
          effect: Effect.capture,
          pieces: piecesAtPosition,
          position: currentPosition,
        });
      }

      currentRow += rowStep;
      currentCol += colStep;
    }

    // Handle the final position (toPosition)
    if (toPiece) {
      this.captureMove(fromPosition, toPosition, piece, toPiece, sideEffects);
    } else {
      this.regularMove(fromPosition, toPosition, piece);
    }

    // Mega Charge logic
    const isMegaCharge = Math.abs(rowDiff) > 2 || Math.abs(colDiff) > 2;
    if (isMegaCharge) {
      piece.hasCharged = true;
    }
  }

  captureMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece,
    sideEffects: SideEffect[]
  ) {
    // Check if the captured piece is a snake that hasn't shed yet
    if (toPiece.type === PieceType.Snake && !toPiece.hasShed) {
      this.snakeLivesMatter(toPiece, toPosition, sideEffects);
    } else {
      // Collect the capture as a side effect
      sideEffects.push({
        effect: Effect.capture,
        pieces: [toPiece],
        position: toPosition,
      });
    }

    // Move the attacking piece
    this.regularMove(fromPosition, toPosition, piece);
  }

  snakeLivesMatter(
    snakePiece: IPiece,
    capturePosition: Position,
    sideEffects: SideEffect[]
  ) {
    // Mark the snake as having shed its skin
    snakePiece.hasShed = true;

    // Determine the respawn position
    const respawnRow = snakePiece.isLight ? 0 : 7;
    const respawnPosition = { row: respawnRow, col: capturePosition.col };

    // Check if the respawn position is occupied
    const pieces = this.gameStateManager.getPiecesAt(respawnPosition);
    if (
      pieces.length === 0 ||
      (pieces.length === 1 &&
        pieces.some((piece) => piece.type === PieceType.Tree))
    ) {
      // move the snake to the respawn spot
      this.regularMove(capturePosition, respawnPosition, snakePiece);
    } else {
      // Collect the capture as a side effect
      sideEffects.push({
        effect: Effect.capture,
        pieces: [snakePiece],
        position: capturePosition,
      });
    }
  }

  houseMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece
  ) {
    this.regularMove(fromPosition, toPosition, piece);
    piece.isHousing = true;
  }

  getValidMoves(position: Position, piece: IPiece): IMove[] {
    if (piece.isPoisoned) {
      // The piece is poisoned and cannot move
      return [];
    }
    if (piece.type === PieceType.Tree && piece.isMeadowed) {
      // The Tree is meadowed and cannot move
      return [];
    }
    if (piece.isEntangled) {
      // The animal is entangled and cannot move
      return [];
    }

    const { row, col } = position;
    const height = this.gameStateManager.getBoardState().length;
    const width = this.gameStateManager.getBoardState()[0]?.length || 0;

    // Check if the position is within the board bounds
    if (row < 0 || row >= height || col < 0 || col >= width) {
      throw new Error("Position is out of bounds");
    }

    // Get the square at the specified position
    const square = this.gameStateManager.getSquare(position);
    if (!square) {
      throw new Error("Square is undefined");
    }

    if (!piece) {
      return [];
    }

    // Get the valid moves for the piece
    return piece.getValidMovesForPiece(
      this.gameStateManager.getBoardState(),
      position
    );
  }

  isCheckmate(): boolean {
    // Implement checkmate logic if necessary
    return false; // Placeholder implementation
  }

  isStalemate(): boolean {
    // Implement stalemate logic if necessary
    return false; // Placeholder implementation
  }

  switchTurn(): void {
    // Implement turn switching logic if necessary
  }

  undoMove(): void {
    // Implement undo move logic if necessary
  }
}
