import React from "react";
import styles from "./BoardContainer.module.css";
import { PieceType } from "../services/zodiacEngine/types";
import { allPieceTypes } from "../services/zodiacEngine/const";
import { Piece } from "../services/zodiacEngine/Piece";
import { Position } from "../services/zodiacEngine/types";

interface PieceSelectorProps {
  onPiecePlaced: (piece: Piece, position: Position) => void;
}

function PieceDrag({ onPiecePlaced }: PieceSelectorProps) {
  const pieceTypes = [
    "Bear",
    "Crane",
    "Frog",
    "Fungi",
    "Goat",
    "Monkey",
    "Octopus",
    "Penguin",
    "Rhino",
    "Snake",
    "Tree",
    "Virus",
    "Wolf",
  ];

  function handleDragStart(
    event: React.DragEvent<HTMLImageElement>,
    piece: string,
    isLight: boolean
  ) {
    const pieceType = mapStringToPieceType(piece);
    event.dataTransfer.setData(
      "piece",
      JSON.stringify({ type: pieceType, isLight })
    );
  }

  function mapStringToPieceType(pieceString: string): PieceType {
    const lowerCasePiece = pieceString.toLowerCase();
    const pieceType = allPieceTypes.find(
      (type) => type.toLowerCase() === lowerCasePiece
    );
    if (pieceType) {
      return pieceType;
    } else {
      throw new Error(`Unknown piece type: ${pieceString}`);
    }
  }

  return (
    <div className={styles.pieceIcons}>
      <div className={styles.lightPieces}>
        {pieceTypes.map((piece) => (
          <div key={`${piece}-light`} className={styles.pieceIconContainer}>
            <img
              src={`/icons/${piece}-light.png`}
              alt={`${piece}-light`}
              className={styles.pieceIcon}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, piece, true)}
            />
          </div>
        ))}
      </div>
      <div className={styles.darkPieces}>
        {pieceTypes.map((piece) => (
          <div key={`${piece}-dark`} className={styles.pieceIconContainer}>
            <img
              src={`/icons/${piece}-dark.png`}
              alt={`${piece}-dark`}
              className={styles.pieceIcon}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, piece, false)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PieceDrag;
