export interface ISquare {
  Pieces: IPiece[];
}

// TODO could we use the type system to prevent off board coords altogether
export interface Position {
  row: number;
  col: number;
}

export enum PieceType {
  Bear = "Bear",
  Crane = "Crane",
  Frog = "Frog",
  Fungi = "Fungi",
  Goat = "Goat",
  Monkey = "Monkey",
  Octopus = "Octopus",
  Penguin = "Penguin",
  Rhino = "Rhino",
  Snake = "Snake",
  Tree = "Tree",
  Wolf = "Wolf",
  Virus = "Virus",
}

export type Direction =
  | "up"
  | "up-right"
  | "right"
  | "down-right"
  | "down"
  | "down-left"
  | "left"
  | "up-left";

export interface IPiece {
  type: PieceType;
  isLight: boolean;
  isKing: boolean;
  isPoisoned: boolean;
  isZombified: boolean;
  isFainted: boolean;
  isEntangled: boolean;
  isShadowed: boolean;
  isInfected: boolean;
  direction: Direction | null;
  hasCharged: boolean;
  hasMoved: boolean;
  hasShed: boolean;
  hasLilyJumped: boolean;
  isMeadowed: boolean;
  isRiding: boolean;
  isHousing: boolean;
  isLone: boolean;
  isDormant: boolean;
  getValidMovesForPiece(boardState: ISquare[][], position: Position): IMove[];
}

export interface IMove {
  position: Position;
  toPiece: IPiece | null;
  moveType: MoveType;
  sideEffects: SideEffect[];
}

export interface SideEffect {
  position: Position;
  extrapositions?: Position[];
  pieces: IPiece[];
  effect: Effect;
}

export enum Effect {
  capture,
  meadow,
  entangle,
  housemove,
  infect,
  respawn,
}

export enum MoveType {
  regular,
  capture,
  simpleentangle,
  moveentangle,
  splitentangle,
  tripleentangle,
  ink,
  house,
  traverse,
  faint,
  jumppad,
  lane,
  kong,
  swing,
  rotate,
  trample,
  infect,
}

export interface GameState {
  mode: Mode;
  board: ISquare[][];
  state: State;
  lightKing?: PieceType;
  darkKing?: PieceType;

  // these should be the unique player id's once auth is enabled
  lightPlayer?: string;
  darkPlayer?: string;
  isLightTurn: boolean;
}

export enum Mode {
  Live = "Live",
  Dev = "Dev",
}

export enum State {
  None = "None",
  Searching = "Searching",
  PreGame = "Pre Game",
  Playing = "Playing",
  PostGame = "Post Game",
  Terminated = "Terminated",
}

export type GameStateListener = () => void;

export interface PieceMovement {
  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[];

  //canCapture(piece: IPiece, target: IPiece): boolean;
}
